import {
  ADD_MANUFACTURE,
  DELETE_MANUFACTURE,
  GET_MANUFACTURE,
  GET_MANUFACTURES,
  RESET_MANUFACTURE,
  GET_METADATA_MANUFACTURE
} from '../constant';

const INITIAL_STATE = {
  data: [],
  selectedData: {}
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_MANUFACTURES:
      return { ...state, data: action.payload };
    case GET_MANUFACTURE:
      return { ...state, selectedData: action.payload };
    case GET_METADATA_MANUFACTURE:
      return { ...state, totalData: action.payload.totalData, limitData: action.payload.limitData };
    case ADD_MANUFACTURE:
      return { ...state, data: [action.payload, ...state.data] };
    case DELETE_MANUFACTURE:
      return { ...state, data: state.data.filter(item => item.id !== action.payload) };
    case RESET_MANUFACTURE:
      return { ...state, selectedData: {} };
    default:
      return state;
  }
};
