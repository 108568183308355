import {
  ADD_ITEM,
  DELETE_ITEM,
  GET_ITEM,
  GET_ITEMS,
  RESET_ITEM,
  SELECT_ITEM,
  FAILED_ADD_ITEM,
  RESET_ADD_ITEM_ERROR,
  FILTER_ITEM,
  CLEAR_FILTER,
  POST_GALLERY,
  DELETE_GALLERY,
  GET_GALLERY,
  CLEAR_ITEM,
  SEARCH_ITEM,
  GET_METADATA_ITEM,
  UPDATE_PROGRESS_GALLERY,
  PIN_ITEM
} from '../constant';

const INITIAL_STATE = {
  data: [],
  selectedData: {},
  selectedGallery: [],
  galleryUpload: 0,
  isSuccessAdd: false,
  selectedId: '',
  error: [],
  filter: '',
  query: '',
  totalData: 0,
  limitData: 0
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ITEMS:
      return { ...state, data: action.payload, selectedGallery: [] };
    case CLEAR_ITEM:
      return { ...state, data: [] };
    case GET_METADATA_ITEM:
      return { ...state, totalData: action.payload.totalData, limitData: action.payload.limitData };
    case GET_ITEM:
      return { ...state, selectedData: action.payload };
    case UPDATE_PROGRESS_GALLERY:
      return { ...state, galleryUpload: action.payload };
    case GET_GALLERY:
      return { ...state, selectedGallery: action.payload };
    case DELETE_GALLERY:
      return {
        ...state,
        selectedGallery: state.selectedGallery.filter(gallery => gallery.id !== action.payload)
      };
    case POST_GALLERY:
      return { ...state, selectedGallery: [action.payload, ...state.selectedGallery] };
    case ADD_ITEM:
      return { ...state, data: [action.payload, ...state.data], isSuccessAdd: true };
    case DELETE_ITEM:
      return { ...state, data: state.data.filter(item => item.id !== action.payload) };
    case PIN_ITEM:
      return { ...state };
    case RESET_ITEM:
      return { ...state, selectedData: {} };
    case SELECT_ITEM:
      return { ...state, selectedId: action.payload };
    case FAILED_ADD_ITEM:
      return { ...state, error: action.payload };
    case RESET_ADD_ITEM_ERROR:
      return { ...state, error: [] };
    case FILTER_ITEM:
      return {
        ...state,
        filter: action.payload
      };
    case SEARCH_ITEM:
      return { ...state, query: action.payload };
    case CLEAR_FILTER:
      return { ...state, filter: '' };
    default:
      return state;
  }
};
