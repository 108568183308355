import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

const Home = lazy(() => import('./Home'));
const Login = lazy(() => import('./Login'));
const NoMatch = lazy(() => import('./NotFound'));

const App = () => (
  <div>
    <BrowserRouter>
      <Suspense fallback={<div>Loading . . .</div>}>
        <Switch>
          <Route path="/d" component={Home} />
          <Route path="/login" component={Login} />
          <Route path="/" exact render={() => <Redirect to="/d" />} />
          <Route component={NoMatch} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  </div>
);

export default App;
