import {
  ADD_BANNER,
  DELETE_BANNER,
  GET_BANNER,
  GET_BANNERS,
  RESET_BANNER,
  GET_METADATA_BANNER
} from '../constant';

const INITIAL_STATE = {
  data: [],
  selectedData: {}
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_BANNERS:
      return { ...state, data: action.payload };
    case GET_BANNER:
      return { ...state, selectedData: action.payload };
    case GET_METADATA_BANNER:
      return { ...state, totalData: action.payload.totalData, limitData: action.payload.limitData };
    case ADD_BANNER:
      return { ...state, data: [action.payload, ...state.data] };
    case DELETE_BANNER:
      return { ...state, data: state.data.filter(item => item.id !== action.payload) };
    case RESET_BANNER:
      return { ...state, selectedData: {} };
    default:
      return state;
  }
};
