import {
  ADD_CATEGORY,
  DELETE_CATEGORY,
  GET_CATEGORIES,
  GET_CATEGORY,
  SEARCH_CATEGORY
} from '../constant';

const INITIAL_STATE = {
  data: [],
  selectedData: {},
  searchedData: [],
  addedData: ''
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CATEGORIES:
      return { ...state, data: action.payload };
    case GET_CATEGORY:
      return { ...state, selectedData: action.payload };
    case SEARCH_CATEGORY:
      return { ...state, searchedData: action.payload };
    case ADD_CATEGORY:
      return { ...state, data: [action.payload, ...state.data], addedData: action.payload };
    case DELETE_CATEGORY:
      return { ...state, data: state.data.filter(item => item.id !== action.payload) };
    default:
      return state;
  }
};
