import { ADD_ADMIN, DELETE_ADMIN, GET_ADMIN, GET_ADMINS, GET_METADATA_ADMIN } from '../constant';

const INITIAL_STATE = {
  data: [],
  selectedData: {},
  addedData: {}
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ADMINS:
      return { ...state, data: action.payload };
    case GET_ADMIN:
      return { ...state, selectedData: action.payload };
    case ADD_ADMIN:
      return { ...state, data: [action.payload.user, ...state.data], addedData: action.payload };
    case DELETE_ADMIN:
      return { ...state, data: state.data.filter(item => item.id !== action.payload) };
    case GET_METADATA_ADMIN:
      return { ...state, totalData: action.payload.totalData, limitData: action.payload.limitData };
    default:
      return state;
  }
};
