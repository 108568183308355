import { ADD_SERIE, DELETE_SERIE, GET_SERIE, GET_SERIES, GET_SERIES_BY_NAME } from '../constant';

const INITIAL_STATE = {
  data: [],
  selectedData: {},
  addedData: {},
  searchedData: []
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SERIES:
      return { ...state, data: action.payload };
    case GET_SERIE:
      return { ...state, selectedData: action.payload };
    case GET_SERIES_BY_NAME:
      return { ...state, searchedData: action.payload };
    case ADD_SERIE:
      return { ...state, data: [action.payload, ...state.data], addedData: action.payload };
    case DELETE_SERIE:
      return { ...state, data: state.data.filter(item => item.id !== action.payload) };
    default:
      return state;
  }
};
