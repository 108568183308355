import {
  FAILED_LOGIN,
  GET_LOGIN,
  GET_LOGOUT,
  RESET_FAILED_LOGIN,
  SUCCESS_LOGIN,
  FAILED_LOGIN_TOKEN
} from '../constant';

const INITIAL_STATE = {
  isLoggedIn: null,
  token: '',
  user: {},
  error: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_LOGIN:
      return { ...state, token: action.payload.token };
    case FAILED_LOGIN:
      return { ...state, error: action.payload, isLoggedIn: false };
    case GET_LOGOUT:
      return { ...state, isLoggedIn: false };
    case RESET_FAILED_LOGIN:
      return { ...state, error: null };
    case FAILED_LOGIN_TOKEN:
      return { ...state, isLoggedIn: false };
    case SUCCESS_LOGIN:
      return { ...state, user: action.payload, isLoggedIn: true };
    default:
      return state;
  }
};
