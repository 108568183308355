import {
  ADD_VOUCHER,
  DELETE_VOUCHER,
  GET_VOUCHER,
  GET_VOUCHERS,
  SEARCH_VOUCHER,
  GET_METADATA_VOUCHER
} from '../constant';

const INITIAL_STATE = {
  data: [],
  selectedData: {},
  addedData: '',
  searchedData: []
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_VOUCHERS:
      return { ...state, data: action.payload };
    case GET_VOUCHER:
      return { ...state, selectedData: action.payload };
    case GET_METADATA_VOUCHER:
      return { ...state, totalData: action.payload.totalData, limitData: action.payload.limitData };
    case SEARCH_VOUCHER:
      return { ...state, searchedData: action.payload };
    case ADD_VOUCHER:
      return { ...state, data: [action.payload, ...state.data], addedData: action.payload };
    case DELETE_VOUCHER:
      return { ...state, data: state.data.filter(item => item.id !== action.payload) };
    default:
      return state;
  }
};
