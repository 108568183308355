import {
  GET_STAT_ITEM,
  GET_STAT_TRANSACTION,
  GET_STAT_USER,
  GET_STAT_DEADLINE,
  GET_STAT_TRANSACTION_ITEM_META,
  GET_STAT_TRANSACTION_ITEM
} from '../constant';

const INITIAL_STATE = {
  user: 0,
  transaction: 0,
  item: 0,
  itemDeadline: [],
  selectedData: {},
  addedData: {},
  searchedData: [],
  transactionItem: []
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_STAT_TRANSACTION:
      return { ...state, transaction: action.payload };
    case GET_STAT_ITEM:
      return { ...state, item: action.payload };
    case GET_STAT_USER:
      return { ...state, user: action.payload };
    case GET_STAT_DEADLINE:
      return { ...state, itemDeadline: action.payload };
    case GET_STAT_TRANSACTION_ITEM:
      return { ...state, transactionItem: action.payload };
    case GET_STAT_TRANSACTION_ITEM_META:
      return { ...state, totalData: action.payload.totalData, limitData: action.payload.limitData };
    default:
      return state;
  }
};
