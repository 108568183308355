import { combineReducers } from 'redux';
import manufactureReducer from './manufactureReducer';
import serieReducer from './serieReducer';
import characterReducer from './characterReducer';
import categoryReducer from './categoryReducer';
import authReducer from './authReducer';
import itemReducer from './itemReducer';
import adminReducer from './adminReducer';
import bannerReducer from './bannerReducer';
import voucherReducer from './voucherReducer';
import statisticReducer from './statisticReducer';
import transactionReducer from './transactionReducer';

export default combineReducers({
  banner: bannerReducer,
  item: itemReducer,
  manufacture: manufactureReducer,
  serie: serieReducer,
  character: characterReducer,
  category: categoryReducer,
  auth: authReducer,
  admin: adminReducer,
  voucher: voucherReducer,
  statistic: statisticReducer,
  transaction: transactionReducer
});
