import {
  ADD_CHARACTER,
  DELETE_CHARACTER,
  GET_CHARACTER,
  GET_CHARACTERS,
  SEARCH_CHARACTER,
  FAILED_ADD_CHARACTER
} from '../constant';

const INITIAL_STATE = {
  data: [],
  selectedData: {},
  addedData: '',
  searchedData: []
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CHARACTERS:
      return { ...state, data: action.payload };
    case GET_CHARACTER:
      return { ...state, selectedData: action.payload };
    case SEARCH_CHARACTER:
      return { ...state, searchedData: action.payload };
    case ADD_CHARACTER:
      return { ...state, data: [action.payload, ...state.data], addedData: action.payload };
    case DELETE_CHARACTER:
      return { ...state, data: state.data.filter(item => item.id !== action.payload) };
    case FAILED_ADD_CHARACTER:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};
