// API BASE URL

let baseUrl = 'api-dev';
if (process.env.REACT_APP_VER === 'production') {
  baseUrl = 'api';
}

export const BASE_URL_USER = `https://${baseUrl}.shumi.shop/api/v1`;
export const BASE_URL_ADMIN = `https://${baseUrl}.shumi.shop/api/v1/shumeme`;
export const BASE_URL_AUTH = `https://${baseUrl}.shumi.shop/api/v1`;

// export const BASE_URL_USER = 'https://api-dev.shumi.shop/api/v1';
// export const BASE_URL_ADMIN = 'https://api-dev.shumi.shop/api/v1/shumeme';
// export const BASE_URL_AUTH = 'https://api-dev.shumi.shop/api/v1';

export const SPACE_URL = 'https://shumi.sgp1.cdn.digitaloceanspaces.com';

export const GET_LOGIN = 'GET_LOGIN';
export const GET_LOGOUT = 'GET_LOGOUT';
export const SUCCESS_LOGIN = 'SUCCESS_LOGIN';
export const FAILED_LOGIN = 'FAILED_LOGIN';
export const RESET_FAILED_LOGIN = 'RESET_FAILED_LOGIN';
export const GET_CHECK_USER = 'GET_CHECK_USER';
export const FAILED_LOGIN_TOKEN = 'FAILED_LOGIN_TOKEN';

export const GET_ITEMS = 'GET_ITEMS';
export const GET_METADATA_ITEM = 'GET_METADATA_ITEM';
export const GET_ITEM = 'GET_ITEM';
export const SELECT_ITEM = 'SELECT_ITEM';
export const DELETE_ITEM = 'DELETE_ITEM';
export const ADD_ITEM = 'ADD_ITEM';
export const RESET_ITEM = 'RESET_ITEM';
export const FAILED_ADD_ITEM = 'FAILED_ADD_ITEM';
export const RESET_ADD_ITEM_ERROR = 'RESET_ADD_ITEM_ERROR';
export const FILTER_ITEM = 'FILTER_ITEM';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const ADD_GALLERY = 'ADD_GALLERY';
export const POST_GALLERY = 'POST_GALLERY';
export const DELETE_GALLERY = 'DELETE_GALLERY';
export const GET_GALLERY = 'GET_GALLERY';
export const UPDATE_PROGRESS_GALLERY = 'UPDATE_PROGRESS_GALLERY';
export const CLEAR_ITEM = 'CLEAR_ITEM';
export const SEARCH_ITEM = 'SEARCH_ITEM';
export const PIN_ITEM = 'PIN_ITEM';

export const GET_MANUFACTURES = 'GET_MANUFACTURES';
export const GET_MANUFACTURE = 'GET_MANUFACTURE';
export const DELETE_MANUFACTURE = 'DELETE_MANUFACTURE';
export const ADD_MANUFACTURE = 'ADD_MANUFACTURE';
export const RESET_MANUFACTURE = 'RESET_MANUFACTURE';
export const GET_METADATA_MANUFACTURE = 'GET_METADATA_MANUFACTURE';

export const GET_BANNERS = 'GET_BANNERS';
export const GET_BANNER = 'GET_BANNER';
export const DELETE_BANNER = 'DELETE_BANNER';
export const ADD_BANNER = 'ADD_BANNER';
export const RESET_BANNER = 'RESET_BANNER';
export const GET_METADATA_BANNER = 'GET_METADATA_BANNER';

export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORY = 'GET_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const SEARCH_CATEGORY = 'SEARCH_CATEGORY';
export const GET_METADATA_CATEGORY = 'GET_METADATA_CATEGORY';
export const FAILED_ADD_CHARACTER = 'FAILED_ADD_CHARACTER';

export const GET_SERIES = 'GET_SERIES';
export const GET_SERIE = 'GET_SERIE';
export const DELETE_SERIE = 'DELETE_SERIE';
export const ADD_SERIE = 'ADD_SERIE';
export const GET_SERIES_BY_NAME = 'GET_SERIES_BY_NAME';
export const GET_METADATA_SERIE = 'GET_METADATA_SERIE';

export const GET_CHARACTERS = 'GET_CHARACTERS';
export const GET_CHARACTER = 'GET_CHARACTER';
export const DELETE_CHARACTER = 'DELETE_CHARACTER';
export const ADD_CHARACTER = 'ADD_CHARACTER';
export const SEARCH_CHARACTER = 'SEARCH_CHARACTER';
export const GET_METADATA_CHARACTER = 'GET_METADATA_CHARACTER';

export const GET_VOUCHERS = 'GET_VOUCHERS';
export const GET_VOUCHER = 'GET_VOUCHER';
export const DELETE_VOUCHER = 'DELETE_VOUCHER';
export const ADD_VOUCHER = 'ADD_VOUCHER';
export const SEARCH_VOUCHER = 'SEARCH_VOUCHER';
export const GET_METADATA_VOUCHER = 'GET_METADATA_VOUCHER';

export const ADD_ADMIN = 'ADD_ADMIN';
export const DELETE_ADMIN = 'DELETE_ADMIN';
export const GET_ADMINS = 'GET_ADMINS';
export const GET_ADMIN = 'GET_ADMIN';
export const SEARCH_ADMIN = 'SEARCH_ADMIN';
export const GET_METADATA_ADMIN = 'GET_METADATA_ADMIN';

export const GET_STAT_TRANSACTION = 'GET_STAT_TRANSACTION';
export const GET_STAT_ITEM = 'GET_STAT_ITEM';
export const GET_STAT_USER = 'GET_STAT_USER';
export const GET_STAT_DEADLINE = 'GET_STAT_DEADLINE';
export const GET_STAT_TRANSACTION_ITEM = 'GET_STAT_TRANSACTION_ITEM';
export const GET_STAT_TRANSACTION_ITEM_META = 'GET_STAT_TRANSACTION_ITEM_META';

export const GET_TRANSACTION = 'GET_TRANSACTION';
export const GET_TRANSACTION_ITEM = 'GET_TRANSACTION_ITEM';
export const GET_TRANSACTION_CONFIRM = 'GET_TRANSACTION_CONFIRM';
export const GET_TRANSACTION_CONFIRM_ITEM = 'GET_TRANSACTION_CONFIRM_ITEM';
export const GET_TRANSACTION_HISTORY = 'GET_TRANSACTION_HISTORY';
export const ADD_RECEIPT = 'ADD_RECEIPT';
export const CONFIRM_ARRIVAL = 'CONFIRM_ARRIVAL';
export const FAILED_CONFIRM_ARRIVAL = 'FAILED_CONFIRM_ARRIVAL';
export const CLEAR_TRANSACTION = 'CLEAR_TRANSACTION';
export const CONFIRM_SHIPMENT = 'CONFIRM_SHIPMENT';
export const FAILED_CONFIRM_SHIPMENT = 'FAILED_CONFIRM_SHIPMENT';
export const GET_METADATA_TRANSACTION = 'GET_METADATA_TRANSACTION';
export const GET_CANCEL_TRANSACTION = 'GET_CANCEL_TRANSACTION';
export const GET_CHANGE_PAID_TRANSACTION = 'GET_CHANGE_PAID_TRANSACTION';
export const GET_PAY_INSTALLMENT = 'GET_PAY_INSTALLMENT';
export const GET_WALLET_TRANSACTIONS = 'GET_WALLET_TRANSACTIONS';
export const CONFIRM_WALLET_TRANSACTION = 'CONFIRM_WALLET_TRANSACTION';

export const paymentMethod = [
  {
    name: 'Shumi Wallet',
    img: 'https://shumi.sgp1.cdn.digitaloceanspaces.com/logos/jenius.png',
    paymentMethod: 'points',
    paymentChannel: 'points'
  },
  {
    name: 'Jenius',
    img: 'https://shumi.sgp1.cdn.digitaloceanspaces.com/logos/jenius.png',
    paymentMethod: 'Jenius',
    paymentChannel: 'Jenius'
  },
  {
    name: 'Mandiri',
    img: 'https://shumi.sgp1.cdn.digitaloceanspaces.com/logos/mandiri.png',
    paymentMethod: 'Mandiri',
    paymentChannel: 'Mandiri'
  },
  {
    name: 'BRI',
    img: 'https://shumi.sgp1.cdn.digitaloceanspaces.com/logos/bri.png',
    paymentMethod: 'BRI',
    paymentChannel: 'BRI'
  },
  {
    name: 'Indomaret',
    img: 'https://shumi.sgp1.cdn.digitaloceanspaces.com/logos/indomaret.png',
    paymentMethod: 'Ipaymu',
    paymentChannel: 'indomaret'
  },
  {
    name: 'Alfamart',
    img: 'https://shumi.sgp1.cdn.digitaloceanspaces.com/logos/alfamart.png',
    paymentMethod: 'Ipaymu',
    paymentChannel: 'alfamart'
  },
  {
    name: 'OVO',
    img: 'https://shumi.sgp1.cdn.digitaloceanspaces.com/logos/ovo.png',
    paymentMethod: 'OVO',
    paymentChannel: 'OVO'
  },
  {
    name: 'BCA',
    img: 'https://shumi.sgp1.cdn.digitaloceanspaces.com/logos/bca.png',
    paymentMethod: 'BCA',
    paymentChannel: 'BCA'
  }
];

export const SOURCE_LEDGER = ['-', 'Kyou', 'MultiToys', 'CN ()', 'JP (Auction)', 'JP ()'];
