import {
  GET_TRANSACTION,
  GET_TRANSACTION_ITEM,
  GET_TRANSACTION_CONFIRM,
  GET_TRANSACTION_CONFIRM_ITEM,
  GET_TRANSACTION_HISTORY,
  CLEAR_TRANSACTION,
  GET_METADATA_TRANSACTION,
  CONFIRM_SHIPMENT,
  CONFIRM_ARRIVAL,
  GET_CANCEL_TRANSACTION,
  GET_CHANGE_PAID_TRANSACTION,
  GET_PAY_INSTALLMENT,
  GET_WALLET_TRANSACTIONS,
  CONFIRM_WALLET_TRANSACTION
} from '../constant';

const INITIAL_STATE = {
  transaction: [],
  transactionItem: [],
  confirmationHistory: [],
  wallet_transaction: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_TRANSACTION:
      return { ...state, transaction: action.payload };
    case GET_WALLET_TRANSACTIONS:
      return { ...state, wallet_transaction: action.payload };
    case CONFIRM_WALLET_TRANSACTION:
      return {
        ...state,
        wallet_transaction: state.wallet_transaction.map(val =>
          val.id === action.payload.id
            ? {
                ...val,
                payment_status: 'Transaction Complete'
              }
            : val
        )
      };
    case GET_TRANSACTION_ITEM:
      return { ...state, transactionItem: action.payload };
    case GET_TRANSACTION_CONFIRM:
      return {
        ...state,
        transaction: state.transaction.map(val =>
          val.id === action.payload.id
            ? {
                ...val,
                payment: { ...val.payment, payment_status: 'Paid' },
                transaction_item: val.transaction_item.map(trItem =>
                  trItem.item_type === 'Ready Stock'
                    ? {
                        ...trItem,
                        payment: { ...trItem.payment, payment_status: action.payload.status }
                      }
                    : {
                        ...trItem,
                        payment: { ...trItem.payment, payment_status: 'Waiting Item Arrival' }
                      }
                )
              }
            : val
        )
      };
    case GET_CANCEL_TRANSACTION:
      return {
        ...state,
        transaction: state.transaction.map(val =>
          val.id === action.payload.id
            ? {
                ...val,
                payment: { ...val.payment, payment_status: 'Expired' },
                transaction_item: val.transaction_item.map(trItem => ({
                  ...trItem,
                  payment: { ...trItem.payment, payment_status: 'Expired' }
                }))
              }
            : val
        )
      };
    case GET_CHANGE_PAID_TRANSACTION:
      return {
        ...state,
        transaction: state.transaction.map(val =>
          val.id === action.payload.id
            ? {
                ...val,
                payment: { ...val.payment, payment_status: action.payload.status },
                transaction_item: val.transaction_item.map(trItem => ({
                  ...trItem,
                  payment: { ...trItem.payment, payment_status: action.payload.status }
                }))
              }
            : val
        )
      };
    case CONFIRM_SHIPMENT:
      return {
        ...state,
        transaction: state.transaction.map(val =>
          val.id === action.payload.idTrans
            ? {
                ...val,
                transaction_item: val.transaction_item.map(item =>
                  item.id === action.payload.id
                    ? {
                        ...item,
                        payment: { ...item.payment, payment_status: 'Shipment Is In Progress' }
                      }
                    : item
                )
              }
            : val
        )
      };
    case CONFIRM_ARRIVAL:
      return {
        ...state,
        transaction: state.transaction.map(val =>
          val.id === action.payload.idTrans
            ? {
                ...val,
                transaction_item: val.transaction_item.map(item =>
                  item.id === action.payload.id
                    ? {
                        ...item,
                        payment: { ...item.payment, payment_status: 'Waiting For Payment' }
                      }
                    : item
                )
              }
            : val
        )
      };
    case GET_TRANSACTION_CONFIRM_ITEM:
      return {
        ...state,
        transaction: state.transaction.map(val =>
          val.id === action.payload.idTrans
            ? {
                ...val,
                transaction_item: val.transaction_item.map(item =>
                  item.id === action.payload.id
                    ? {
                        ...item,
                        payment: { ...item.payment, payment_status: 'Paid' }
                      }
                    : item
                )
              }
            : val
        )
      };
    case GET_PAY_INSTALLMENT:
      return {
        ...state,
        transaction: state.transaction.map(val =>
          val.id === action.payload.orderId
            ? {
                ...val,
                payment: {
                  ...val.payment,
                  total_payment: val.payment.total_payment + action.payload.amount * -1,
                  remain_payment: val.payment.remain_payment + action.payload.amount
                },
                transaction_item: val.transaction_item.map(item =>
                  item.id === action.payload.id
                    ? {
                        ...item,
                        price_dp: item.price_dp + action.payload.amount * -1,
                        payment: {
                          ...item.payment,
                          remain_payment: item.payment.remain_payment + action.payload.amount
                        }
                      }
                    : item
                )
              }
            : val
        )
      };
    case GET_TRANSACTION_HISTORY:
      return { ...state, confirmationHistory: action.payload };
    case CLEAR_TRANSACTION:
      return { ...state, transaction: [] };
    case GET_METADATA_TRANSACTION:
      return { ...state, totalData: action.payload.totalData, limitData: action.payload.limitData };
    default:
      return state;
  }
};

const handleChangeStatusOnConfirm = trItem => {};
